@import "~font-awesome/css/font-awesome.min.css";
@import "~react-toggle/style.css";
@import "~react-table/react-table.css";
@import "~toastr/build/toastr.min.css";

// Import Custom Variables
@import "variables.scss";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// Import Custom SCSS
@import "global.scss";

// Layout
@import "layout/authentication.scss";
@import "layout/dashboard-default.scss";
@import "layout/dashboard-fixed.scss";
@import "layout/error.scss";

// Navigation
@import "navigation/nav.scss";
@import "navigation/topnav.scss";
@import "navigation/sidenav/sidenav.scss";
@import "navigation/sidenav/sidenav-dark.scss";
@import "navigation/sidenav/sidenav-light.scss";



* > .selected-row {
  $selected-bg-row: #d9eaff;
  background-color: $selected-bg-row !important;

  &:nth-child(odd) {
    background-color: lighten($selected-bg-row, 3) !important;
  }
}


@mixin space-child($property, $margin-selector, $margin) {
  #{$property} {
    > *:not(:last-child) {
      #{$margin-selector}: $margin;
    }
  }
}

@include space-child('.child-spacing-x-1', margin-right, map-get($spacers, 1));
@include space-child('.child-spacing-x-2', margin-right, map-get($spacers, 2));
@include space-child('.child-spacing-x-3', margin-right, map-get($spacers, 3));
@include space-child('.child-spacing-x-4', margin-right, map-get($spacers, 4));
@include space-child('.child-spacing-x-5', margin-right, map-get($spacers, 5));

@include space-child('.child-spacing-y-1', margin-bottom, map-get($spacers, 1));
@include space-child('.child-spacing-y-2', margin-bottom, map-get($spacers, 2));
@include space-child('.child-spacing-y-3', margin-bottom, map-get($spacers, 3));
@include space-child('.child-spacing-y-4', margin-bottom, map-get($spacers, 4));
@include space-child('.child-spacing-y-5', margin-bottom, map-get($spacers, 5));

.order-products {
  > .order-product-row {
    padding-top: 7px;
    padding-bottom: 7px;
    &:nth-child(odd) {
      background-color: #ececec;

      &.selected-product {
        background-color: darken(#c2eafd, 10);
      }
    }
    &:nth-child(even) {
      border-bottom: 1px solid #ececec;
    }

    &.selected-product {
      background-color: #c2eafd;
    }
  }
}

.flex-1 {
  flex: 1;
}

#toast-container {
  .toast-success {
    background-color: #008d00;
  }
}
